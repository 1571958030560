import { analyticsService, assertNotNil } from 'venn-utils';
import { TutorialContent } from './TutorialContent';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Button, getAppTitle, GetColor, INTERPOLATION_EXTRAPOLATION_FAQ_HREF } from 'venn-ui-kit';

const Headline = () => (
  <>
    <div>
      <New>{`New from ${getAppTitle()}`}</New>:
    </div>
    <div>Preview expanded proxy support with extrapolation</div>
    <div>for assets with less frequent reporting.</div>
  </>
);

const trackCta = () => {
  analyticsService.ctaClicked({
    destination: 'Extrapolation help center article',
    filled: true,
    locationOnPage: 'Extrapolation carousel tab',
    purpose: 'Learn more about extrapolation',
    text: 'Learn more',
    type: 'button',
  });
};
const ExtrapolationTab = () => {
  const { Images } = useContext(ThemeContext);

  return (
    <TutorialContent
      headline={Headline()}
      images={[assertNotNil(Images.extrapolationAsset)]}
      hideBorder
      assetWidth="720px"
      assetHeight="200px"
    >
      <a href={INTERPOLATION_EXTRAPOLATION_FAQ_HREF} target="_blank" rel="noopener noreferrer">
        <Button dominant className="qa-check-it" data-testid="qa-check-it" onClick={trackCta}>
          Learn more
        </Button>
      </a>
    </TutorialContent>
  );
};

export default ExtrapolationTab;

const New = styled.div`
  color: ${GetColor.HighlightDark};
  font-weight: bold;
  display: inline-block;
  font-style: italic;
`;
