import React from 'react';
import { Icon, TooltipBodyDirection, TooltipPosition } from 'venn-ui-kit';
import ProxyInfo from '../modals/proxy-cell/ProxyInfo';
import type { ProxyTriggerProps, ProxySelectorProps } from '../modals/proxy-cell/ProxySelector';
import ProxySelector from '../modals/proxy-cell/ProxySelector';
import {
  YellowButtonContent,
  YellowButtonIcon,
  YellowButtonProxyName,
  YellowButtonWrapper,
} from './YellowButtonStyles';
import { useHasFF } from 'venn-utils';

type ProxyIconButtonProps = Omit<ProxySelectorProps, 'children'>;

const ProxyIconButton = ({
  proxy,
  investment,
  onProxyChange,
  dropdownVerticalDirection,
  usePortal,
  loading,
  readOnly,
}: ProxyIconButtonProps) => {
  const hasExtrapolationFF = useHasFF('extrapolation_ff');
  return (
    <ProxySelector
      proxy={proxy}
      investment={investment}
      hoverAddProxy={false}
      onProxyChange={onProxyChange}
      dropdownVerticalDirection={dropdownVerticalDirection}
      usePortal={usePortal}
      portalLeftOffset={82}
      portalTopOffset={-5}
      readOnly={readOnly}
    >
      {({ selectedProxy, selectorOpen, onToggleSelector, isProxyUpdating }: ProxyTriggerProps) => {
        const extrapolateMsg = hasExtrapolationFF ? ' extrapolate' : '';
        return (
          <YellowButtonWrapper>
            <YellowButtonIcon
              className={selectedProxy?.name ? 'qa-proxy-name qa-edit-label' : undefined}
              disabled={isProxyUpdating || loading || readOnly}
              active={selectorOpen}
              customIcon={
                <YellowButtonContent>
                  <Icon type="arrow-from-right" />
                  {selectedProxy?.name && (
                    <YellowButtonProxyName disabled={isProxyUpdating || loading}>
                      {selectedProxy?.name}
                    </YellowButtonProxyName>
                  )}
                </YellowButtonContent>
              }
              border
              onClick={readOnly ? undefined : onToggleSelector}
              tooltip={
                selectedProxy ? (
                  <ProxyInfo
                    key={investment.id}
                    investmentLastUpdated={investment.updated}
                    investmentId={investment.id}
                    proxy={selectedProxy}
                    investmentFrequency={investment.frequency}
                  />
                ) : (
                  <div>
                    <h4>
                      <strong>Proxy Data</strong>
                    </h4>
                    <div>
                      {`Backfill, substitute, desmooth,${extrapolateMsg} or interpolate your data with an investment with similar returns.`}
                    </div>
                  </div>
                )
              }
              tooltipMaxWidth={300}
              tooltipPosition={selectedProxy?.name ? TooltipPosition.Bottom : TooltipPosition.Left}
              tooltipDirection={TooltipBodyDirection.Left}
            />
          </YellowButtonWrapper>
        );
      }}
    </ProxySelector>
  );
};

export default ProxyIconButton;
