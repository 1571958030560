import { default as DefaultTextMap } from '../default/DefaultTextMap';
import { getCurrentTheme } from './getCurrentTheme';
import type { FooterLink } from 'venn-components';

export interface IHelpCenterLinks {
  INTERCOM_HELP_ALL_HREF: string;
  FACTOR_EXPOSURE_HREF: string;
  FACTOR_CONTRIBUTION_TO_RISK_HREF: string;
  FACTOR_CONTRIBUTION_TO_RETURN_HREF: string;
  INTERCOM_HELP_HREF: string;
  METRIC_DEFINITIONS_HREF: string;
  FORECASTS_FAQ_HREF: string;
  DEMO_PORTFOLIO_FAQ_HREF: string;
  DEMO_PRIVATE_PORTFOLIO_FAQ_HREF: string;
  STUDIO_FAQ_HREF: string;
  BENCHMARK_FAQ_HREF: string;
  FORECAST_RETURN_DEF_HREF: string;
  EXCESS_RETURN_DEF_HREF: string;
  TRACKING_ERROR_DEF_HREF: string;
  INFORMATION_RATIO_DEF_HREF: string;
  MAX_UNDERPERFORMANCE_DEF_HREF: string;
  HISTORICAL_RISK_STATISTICS_HREF: string;
  PORTFOLIO_LAB_FAQ_HREF: string;
  ADVANCED_SEARCH_FAQ_HREF: string;
  HOLDINGS_FAQ_HREF: string;
  VENNCAST_FAQ_HREF: string;
  CURRENCY_CONVERSION_ARTICLE_HREF: string;
  REBALANCE_STRATEGY_ARTICLE_HREF: string;
  INVESTMENT_CORRELATION_TOOL_HREF: string;
  FACTOR_LENS_FAQ_HREF: string;
  CURRENCY_FAQ_HREF: string;
  PROXY_FAQ_HREF: string;
  DESMOOTHING_FAQ_HREF: string;
  INTERPOLATION_EXTRAPOLATION_FAQ_HREF: string;
  WORKSPACE_MANAGEMENT_ARTICLE_HREF: string;
  GROWTH_SIMULATOR_FAQ_HREF: string;
  SCENARIO_ANALYSIS_FAQ_HREF: string;
  SENSITIVITY_ANALYSIS_FAQ_HREF: string;
  NOTABLE_PERIODS_FAQ_HREF: string;
  CATEGORIES_FAQ_HREF: string;
  FACTOR_ALERTS_FAQ_HREF: string;
  HISTORICAL_DRAWDOWN_FAQ_HREF: string;
  ACCESSING_ANALYSES_FAQ_HREF: string;
  INVESTMENT_UPLOADER_FAQ_HREF: string;
  RETURNS_UPLOADER_FAQ_HREF: string;
  MASTER_PORTFOLIO_ARTICLE_HREF: string;
  ADDEPAR_HELP_HREF: string;
  COMMON_BENCHMARKS_HELP_HREF: string;
  PORTFOLIO_COMPARISON_HELP_HREF: string;
  PRIVATE_ASSET_LAB_HELP_HREF: string;
  PEER_GROUP_ANALYSIS_HELP_HREF: string;
  PRIVATE_ASSET_UPLOADER_FAQ_HREF: string;
  PRIVATE_ASSET_CASH_FLOW_HELP_HREF: string;
  PRIVATE_PERFORMANCE_HELP_HREF: string;
  PORTFOLIO_CONSTRUCTION_FAQ_HREF: string;
  STUDIO_REPORT_LAB_AIS_FAQ_HREF: string;
  MISSING_INVESTMENTS_FAQ_HREF: string;
  FEATURE_OVERVIEW_REPORT_LAB_HREF: string;
  PUBLIC_PRIVATE_ASSET_GROWTH_HREF: string;
  MULTI_PORTFOLIO_UPLOADER_FAQ_HREF: string;
}

export interface IMarcomSiteLinks {
  FACTORS_HOLISTIC_URL: string;
  FACTORS_PARSIMONIOUS_URL: string;
  FACTORS_ORTHOGONAL_URL: string;
  FACTORS_ACTIONABLE_URL: string;
  VENNSIGHTS_URL: string;
  REVERSE_OPTIMIZATION_URL: string;
  RESIDUALIZATION_URL: string;
  DISCLAIMERS: string;
}

export interface ILegalDocs {
  COOKIE_POLICY: FooterLink;
  CRS_LINK: FooterLink;
  PRIVACY_POLICY_LINK: FooterLink;
  SUBSCRIBER_AGREEMENT_LINK: FooterLink;
  USER_AGREEMENT_LINK: FooterLink;
  SERVICE_TIERS_LINK: string;
}
const GreenTextMap = (
  process.env.USE_GREEN === 'true' || process.env.NODE_ENV === 'test'
    ? require('../green/GreenTextMap').default
    : require('../default/DefaultTextMap').default
) as TextThemeProvider;

export type TextThemeProvider = {
  AppTitle: string;
  VenncastName: string;
  HelpCenterUrl: string;
  MarcomSiteUrl: string;
  MarcomSiteLinks: IMarcomSiteLinks;
  HelpCenterLinks: IHelpCenterLinks;
  legalDocuments: ILegalDocs;
  tsParam: string;
  supportEmail: string;
  salesEmail: string;
};

/** This returns a map of text strings tied to the current theme.
 * This allows us to do this like change the app name and title based on the
 * theme.
 */
export const getTextThemeProvider = () => {
  const theme = getCurrentTheme();
  switch (theme) {
    case 'green':
      return GreenTextMap;
    case 'default':
      return DefaultTextMap;
    default:
      throw new Error(`Expected green or default but received ${theme}}`);
  }
};
