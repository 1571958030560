import { toEndOfPeriod } from 'venn-utils';

export const DAY_SIZE = 213;
export const MONTH_SIZE = 189;
export const YEAR_SIZE = 141;
/** Available factors start date */
export const DEFAULT_START_DATE = '1997/10/03';

/** The maximum range possible: from the start of factor data to today. */
export const FACTOR_MAX_RANGE = {
  from: toEndOfPeriod(DEFAULT_START_DATE, 'day'),
  to: toEndOfPeriod(new Date().valueOf(), 'day'),
};
