import React, { useCallback } from 'react';
import classNames from 'classnames';
import type { DateRange, Granularity } from './types';
import Trigger from './trigger/Trigger';
import { formatRange } from './trigger/logic';
import { SkeletalDropMenu } from '../drop-menu';
import noop from 'lodash/noop';
import type { RangeType } from './range-picker/RangePicker';
import { DateRangePickerContent, useDateRangePickerContent } from './DateRangePickerContent';
import { getRangeFromType } from './granularities/logic';
import { FACTOR_MAX_RANGE } from './constants';

interface DateRangePickerProps {
  /**
   * Dates (from and to) expressed in UTC Timestamp
   */
  value?: DateRange;
  /**
   * Maximum available range for the selected dates, in UTC Timestamp.
   * If not provided, you cannot select a date.
   */
  range?: DateRange;
  /**
   * Type of date selector: Day, Month or Year
   */
  granularity: Granularity;
  /**
   * Whether the picker will open on the right. The default is on the left.
   */
  right?: boolean;
  /**
   * If true, the component will be disabled
   */
  disabled?: boolean;
  /**
   * Alternate style with clear background, no borders and white text
   */
  altStyle?: boolean;
  /**
   * Whether datepicker opens in a portal or not.
   */
  usePortal?: boolean;
  /**
   * Range options to allow within the datepicker
   */
  options?: RangeType[];
  /**
   * Allows specifying a custom trigger
   */
  trigger?: (
    label: string,
    granularity: Granularity,
    opened: boolean,
    disabled: boolean,
    altStyle: boolean,
    onClick: () => void,
    value?: DateRange,
  ) => JSX.Element;
  className?: string;
  onChange?: (value: DateRange) => void;
}

const DateRangePicker = ({
  value,
  range,
  granularity,
  right,
  disabled,
  altStyle,
  className,
  options,
  trigger,
  onChange,
  usePortal,
  ...nonConstraintProps
}: DateRangePickerProps) => {
  const { computedMaxRange, computedValue } = useDateRangePickerContent(range, granularity, value);

  const MenuComponent = useCallback(
    (onApply: (value: DateRange) => void, onClose: () => void) => {
      const onUpdatePeriod = (newRange: RangeType) => {
        onApply?.(getRangeFromType(newRange, computedMaxRange || value || FACTOR_MAX_RANGE, granularity));
      };

      return (
        <DateRangePickerContent
          value={computedValue}
          maxRange={computedMaxRange}
          onUpdatePeriod={onUpdatePeriod}
          onApply={onApply}
          onCancel={onClose}
          options={options}
          layout={right ? 'left' : 'right'}
          granularity={granularity}
          {...nonConstraintProps}
        />
      );
    },
    [computedValue, computedMaxRange, options, right, granularity, nonConstraintProps, value],
  );

  return (
    <SkeletalDropMenu
      className={classNames(className, 'qa-date-picker')}
      triggerComponent={(expanded: boolean, toggleMenu: () => void) =>
        trigger ? (
          trigger(
            formatRange(computedValue, granularity),
            granularity,
            expanded,
            disabled!,
            !!altStyle,
            toggleMenu,
            computedValue,
          )
        ) : (
          <Trigger
            granularity={granularity}
            value={computedValue}
            disabled={disabled || !computedMaxRange}
            opened={expanded}
            className="qa-date-picker-trigger"
            onClick={toggleMenu}
            altStyle={altStyle}
          />
        )
      }
      menuComponent={MenuComponent}
      onApply={onChange ?? noop}
      disabled={disabled || !computedValue || !computedMaxRange}
      usePortal={usePortal}
      menuPosition={right ? 'right' : 'left'}
    />
  );
};

export default DateRangePicker;
