import { groupBy, isNil } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import type { MultiPortfolioPersistResult, PortfolioPersistStatusEnum } from 'venn-api';
import { Flexbox, GetColor, ItemIcon, ItemType } from 'venn-ui-kit';
import { LibraryItemType, LibraryTab, navigateToLibrary } from 'venn-utils';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';
import { ScrollableSectionWithStickyHeader } from './review/ScrollableSectionWithStickyHeader';
import { MainUploadWrapper } from './shared/layout';

type MultiPortfolioConfirmationStepProps = Readonly<{
  persistResult: MultiPortfolioPersistResult;
}>;

const STATUSES_IN_ORDER: PortfolioPersistStatusEnum[] = ['UPDATED', 'CREATED', 'SKIPPED', 'FAILED'];

const STATUS_LABELS: Record<PortfolioPersistStatusEnum, string> = {
  UPDATED: 'Existing portfolios updated',
  CREATED: 'New portfolios created',
  SKIPPED: 'Portfolios excluded from upload',
  FAILED: 'Portfolios failed to upload',
};

type PortfolioRowProps = Readonly<{ name: string; rightContent?: string }>;

const PortfolioRow = ({ name, rightContent }: PortfolioRowProps) => {
  return (
    <PortfolioRowWrapper>
      <Flexbox direction="row" gap={4}>
        <ItemIcon item={ItemType.Portfolio} />
        <span>{name}</span>
      </Flexbox>
      <RightContent>{rightContent}</RightContent>
    </PortfolioRowWrapper>
  );
};

const MultiPortfolioConfirmationStep = ({ persistResult }: MultiPortfolioConfirmationStepProps) => {
  const resultsByStatus = groupBy(persistResult.portfolioPersistResults, 'status');
  const sectionsCount = Object.keys(resultsByStatus).length;

  const { closeFlyout } = useContext(UniversalUploaderContext);

  const history = useHistory();

  const openLibrary = () => {
    navigateToLibrary(history, {
      tab: LibraryTab.ReturnsData,
      selectedFilters: {
        itemType: LibraryItemType.PRO_FORMA,
        quickFilters: [],
        tags: [],
      },
      sortByUpdated: true,
    });
  };

  let sectionIndex = -1;

  return (
    <MainUploadWrapper>
      <Scrollable>
        {STATUSES_IN_ORDER.map((status) => {
          const results = resultsByStatus[status];

          if (isNil(results)) {
            return null;
          }

          sectionIndex++;

          return (
            <ScrollableSectionWithStickyHeader
              key={status}
              title={STATUS_LABELS[status]}
              count={results.length}
              groupIndex={sectionIndex}
              groupReverseIndex={sectionsCount - sectionIndex - 1}
            >
              {results.map(({ portfolio }, index) => (
                <PortfolioRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  name={portfolio.name}
                  rightContent={status === 'UPDATED' ? 'Entire portfolio overwritten' : undefined}
                />
              ))}
            </ScrollableSectionWithStickyHeader>
          );
        })}
      </Scrollable>
      <UniversalUploaderFooter
        noMargin
        secondaryLabel="Close"
        onStartOver={closeFlyout}
        primaryLabel="Go To Data Library"
        onContinue={() => {
          closeFlyout();
          openLibrary();
        }}
      />
    </MainUploadWrapper>
  );
};

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
  align-self: stretch;
`;

const PortfolioRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
`;

const RightContent = styled.div`
  color: ${GetColor.GreyScale.Grey80};
`;

export default MultiPortfolioConfirmationStep;
