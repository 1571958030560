import styled, { css } from 'styled-components';
import React, { useEffect } from 'react';
import { GetColor, Icon, portalMenuIgnoreActivityClassName, RelativePortal, ZIndex } from 'venn-ui-kit';
import type { RowData } from '../../../../modals/data-uploader/types';
import SearchMenuBar from '../../../../search-menu/SearchMenuBar';
import { analyticsService, useModal } from 'venn-utils';
import { isNil, noop } from 'lodash';
import type { Fund, Portfolio } from 'venn-api';
import type { ICellRendererParams } from 'ag-grid-community';

export interface MappedPortfolioNodeCellRendererProps extends ICellRendererParams<RowData<Portfolio>> {
  allFundIdsInPortfolio: string[];
  isFund: boolean;
  needsMapping: boolean;
  onApplyMapping: (path: number[], fund: Fund) => void;
}

const useCloseSearchOnScroll = (isSearchOpen: boolean, closeFn: () => void) => {
  useEffect(() => {
    const handleScroll = () => {
      closeFn();
    };

    if (!isSearchOpen) {
      return () => {};
    }

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [isSearchOpen, closeFn]);
};

export const MappedPortfolioNodeCellRenderer = ({
  allFundIdsInPortfolio,
  isFund,
  onApplyMapping,
  needsMapping,
  node,
}: MappedPortfolioNodeCellRendererProps) => {
  const [isSearchOpen, openSearch, closeSearch] = useModal();
  useCloseSearchOnScroll(isSearchOpen, closeSearch);
  if (!isFund) {
    return null;
  }

  const fundName = node?.data?.node?.name;
  return (
    <>
      <ApplyMappingButton aria-label="apply mapping" needsMapping={needsMapping} onClick={openSearch}>
        {needsMapping ? 'Map' : <Icon data-testid="qa-check-mapping-icon" type="check" />}
        <Icon type="magnifying-glass" />
      </ApplyMappingButton>
      {isSearchOpen && (
        <RelativePortal
          expectedHeight={480}
          style={{ zIndex: ZIndex.InterComFront }}
          onOutClick={noop}
          className={portalMenuIgnoreActivityClassName}
        >
          <SearchWrapper>
            <SearchMenuBar
              refreshedStyling
              autofocus
              defaultMenuIsOpen
              privateAssetSearchMode="PUBLIC_ONLY"
              investmentsOnly
              isOptionDisabled={(option) => {
                const fundId = option?.value?.fund?.id;
                if (!fundId) {
                  // if it does not have a fund ID, it is a tag; we should allow expanding them
                  return false;
                }
                return allFundIdsInPortfolio.includes(fundId);
              }}
              customPlaceholder={`Search for a fund to replace ${!isNil(fundName) ? `'${fundName}'` : 'your investment'}`}
              optionDisabledTooltipContent={() => 'Fund already in portfolio.'}
              getOptionValue={(item) => item.value?.id?.toString() ?? ''}
              onBlur={closeSearch}
              onSelected={(selectedItem) => {
                if (!selectedItem?.value?.fund) {
                  return;
                }
                onApplyMapping((node?.data?.path ?? []).slice(1), selectedItem.value.fund);
                closeSearch();
                analyticsService.navigationTriggered({
                  itemType: 'search menu',
                  userIntent: 'map investment',
                  destinationPageTitle: 'Portfolio Multi Uploader',
                });

                analyticsService.multiPortfolioUploaderInvestmentRemapped({
                  portfolioId: node?.data?.root?.id,
                  portfolioName: node?.data?.root?.name,
                  newInvestmentId: selectedItem.value.fund.id,
                  newInvestmentName: selectedItem.value.fund.name,
                  oldInvestmentId: node?.data?.node?.fund?.id,
                  oldInvestmentName: node?.data?.node?.name,
                });
              }}
              location="portfolio-multi-uploader"
            />
          </SearchWrapper>
        </RelativePortal>
      )}
    </>
  );
};

const ApplyMappingButton = styled.button<{ needsMapping: boolean }>`
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  gap: 6px;
  font-size: 12px;
  border-radius: 4px;
  min-width: 60px;
  ${({ needsMapping }) =>
    needsMapping &&
    css`
      background-color: ${GetColor.Error};
    `};
  border: 1px solid ${({ needsMapping }) => (needsMapping ? GetColor.Error : GetColor.GreyScale.Grey80)};
  color: ${({ needsMapping }) => (needsMapping ? GetColor.White : GetColor.GreyScale.Grey80)};

  :hover {
    color: ${({ needsMapping }) => (needsMapping ? GetColor.White : GetColor.GreyScale.Grey80)};
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  top: 40px;
  left: -600px;
  z-index: ${ZIndex.InterComFront2};
  width: 600px;
  font-weight: normal;
  font-size: 14px;
`;
