import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockSettings, holdingsCategories } from 'venn-state';
import type { ErrorWrapper } from './errorWrapperTypes';
import { StyledErrorState } from '../StyledErrorState';
import { useDebugValue } from 'venn-utils';

export const SectorExposureErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const categories = useRecoilValue(holdingsCategories(blockId));
  const forceError = useDebugValue('debugBlockErrorState') === 'SectorExposure';

  if (blockSetting.customBlockType === 'SECTOR_EXPOSURE') {
    const selectedCategories = categories.flatMap((c) => c.children);
    if (forceError || selectedCategories.length === 0) {
      return (
        <StyledErrorState
          selectedRefId={blockId}
          header="Select at least one sector"
          message="Configure sectors in the right side panel."
        />
      );
    }
  }

  return <>{children}</>;
};
